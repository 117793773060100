import { createApp } from "vue"
import App from "./App.vue"
import { router } from "./router"
import firebase from "firebase/compat/app"
import "firebase/compat/firestore"
import "firebase/compat/auth"
import { startOrRefreshCollectionsSync } from "./collections"
import { initClientAuth } from "@/shared_stuff/client_auth"
import { getIdToken, CONDENS_STAGING_FUNCTIONS_URL } from "@/utils"

initClientAuth(CONDENS_STAGING_FUNCTIONS_URL)

const initializeFirebase = async () =>
	await new Promise(resolve => {
		const config = {
			apiKey: "AIzaSyARfGnDMYi5UMIW3PG_TncZpF388DB8XUM",
			authDomain: "condens-de.firebaseapp.com",
			databaseURL: "https://condens-de.firebaseio.com",
			projectId: "condens-de",
			storageBucket: "condens-de.appspot.com",
			messagingSenderId: "722019409695",
		}
		firebase.initializeApp(config)
		const unsubscribe = firebase.auth().onAuthStateChanged(() => {
			unsubscribe()
			resolve()
		})
	})

;(async () => {
	await initializeFirebase()
	await getIdToken()
	await startOrRefreshCollectionsSync()
	const vueApp = createApp(App)
	vueApp.use(router)
	vueApp.mount("#app")
})()
