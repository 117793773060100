import { formatRelative, formatDistance } from "date-fns"
import { de } from "date-fns/locale"
import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import axios from "axios"
import qs from "qs"
import LRU from "lru-cache"
import { clientAuth, callCondensFunctionFromFrontend } from "@/shared_stuff/client_auth"
import _ from "lodash"
import { router } from "@/router"
import { getEnvVar, regionFromWorkspaceId } from "@/shared_stuff/utils"

export const extractOrgId = workspaceId =>
	_.isString(workspaceId) ? workspaceId.split("#")[0] : null

const filterRoutes = session => {
	if (session.product !== "repo") {
		return session
	}
	const ROUTES_TO_HIDE = ["/pc/", "/p/", "/shared/"]
	return {
		...session,
		routes: session.routes.map(r => {
			if (ROUTES_TO_HIDE.every(start => !r.route.startsWith(start))) {
				return r
			}
			const OBFUSCATION_SUFFIX = "xxxxxx"
			return {
				...r,
				route:
					r.route.substring(0, r.route.length - OBFUSCATION_SUFFIX.length) + OBFUSCATION_SUFFIX,
			}
		}),
	}
}

const sessionsCache = new LRU({ maxSize: 100000, sizeCalculation: () => 1 })

export const splitUpAndGroupSessions = sessions => {
	const result = []
	for (const session of sessions) {
		const fromCache = sessionsCache.get(session.id)
		if (fromCache != null) {
			if (fromCache.updated === session.updated) {
				result.push(...fromCache.entries)
				continue
			}
		}
		let periods = []
		if (session.periods == null) {
			console.error("no periods for session, skipping", session.id)
		} else {
			periods = _.cloneDeep(session.periods)
			for (const p of periods) {
				if (p.from > p.to) {
					p.to += 3 * 60 * 1000
				}
			}
			if (session.currentPeriod != null) {
				let period = session.currentPeriod
				// remove this later, this is for backwards compatibility
				if (_.isArray(session.currentPeriod)) {
					period = { from: period[0], to: period[1] }
				}
				periods.push({ from: period.from, to: Math.min(Date.now(), period.to + 4 * 60 * 1000) })
			}
		}
		if (periods.length === 0) {
			continue
		}
		const routes = session.routes || []
		const sharedData = {
			id: session.id,
			..._.omit(session, "becameActive", "becameInactive", "lastSeenActive", "routes"),
		}
		const entries = []
		for (const { from, to } of periods) {
			const fromDate = new Date(from)
			const toDate = new Date(to)
			let routesInRange = routes.filter(r => _.inRange(r.timestamp, from, to))
			if (routesInRange.length === 0) {
				const routeBefore = _.last(routes.filter(r => r.timestamp < from))
				if (routeBefore != null) {
					routesInRange = [routeBefore]
				}
			}
			const entry = {
				...sharedData,
				from,
				to,
				toDate,
				durationString: formatDistance(fromDate, toDate, { locale: de }),
				routes: routesInRange.map(r => ({
					...r,
					timestampString: formatRelative(new Date(r.timestamp), new Date()),
				})),
			}
			entries.push(entry)
			result.push(entry)
		}
		sessionsCache.set(session.id, { updated: session.updated, entries })
	}
	const aDayAgo = new Date().getTime() - 24 * 60 * 60 * 1000
	const msActiveLastDay = s => Math.max(0, s.to - Math.max(s.from, aDayAgo))

	const grouped = Object.entries(_.groupBy(result, s => s.uid)).map(([uid, sessions]) => {
		sessions = _.sortBy(sessions, s => -s.to)
		return {
			uid,
			lastDayActiveString: formatDistance(0, _.sumBy(sessions, msActiveLastDay)),
			lastDayPages: _.flatten(sessions.map(s => s.routes)).filter(r => r.timestamp > aDayAgo),
			latestVersion: sessions[0].version,
			sessions: sessions.map(filterRoutes),
		}
	})
	return _.sortBy(grouped, g => -g.sessions[0].to)
}

export const CONDENS_STAGING_FUNCTIONS_URL = "https://functions-staging.condens.io"
// export const CONDENS_STAGING_FUNCTIONS_URL = "https://local.condens.io:3100"

export const getIdToken = async () => {
	if (firebase.auth().currentUser == null) {
		if (!currentUserHasAccess()) {
			const loginPath = router.resolve({ name: "login" }).href
			if (window.location.pathname !== loginPath) {
				window.location = loginPath
			}
			return null
		}
		await clientAuth.ensureIdTokenNotExpiredIfLoggedIn()
		const token = await callCondensFunctionFromFrontend(
			`${CONDENS_STAGING_FUNCTIONS_URL}/internal/customFirebaseToken`,
			{},
			{ method: "post" }
		)
		await firebase.auth().signInWithCustomToken(token)
	}
	return await firebase.auth().currentUser.getIdToken()
}

const FUNCTION_SERVER_URLS = getEnvVar("VUE_APP_FUNCTIONS_SERVER_URLS", {
	required: true,
	json: true,
})

const getFunctionsServerUrl = region => {
	if (region == null) {
		window.alert("Region not set\nPlease tell Matej what you did so that he can fix it.")
		throw Error(`Region not set`)
	}
	const url = FUNCTION_SERVER_URLS[region]
	if (url == null) {
		window.alert(
			`Functions server url not found for region=${region}, FUNCTION_SERVER_URLS=${JSON.stringify(
				FUNCTION_SERVER_URLS
			)}\nPlease tell Matej what you did so that he can fix it.`
		)
		throw Error(`Region not set`)
	}
	return url
}

export const callCondensFunction = async (
	name,
	params,
	{ method = "get", region, pickRegionFor } = {}
) => {
	if (pickRegionFor != null) {
		region = regionFromWorkspaceId(pickRegionFor)
	}
	const url = `${getFunctionsServerUrl(region)}/${name}`
	const firebaseIdToken = await getIdToken()
	let result = null
	if (method === "get") {
		result = await axios.get(url, { params: { ...params, firebaseIdToken } })
	} else if (method === "post") {
		result = await axios.post(url, params, { params: { firebaseIdToken } })
	}
	return result.data
}

export const currentUserHasAccess = () => {
	if (firebase.auth().currentUser != null) {
		return true
	}
	const user = clientAuth.loggedInUserInfo()
	return user != null && user.orgId === "condens" && user.role === "admin"
}

export const downloadFromCloudFunction = async (
	name,
	params = {},
	{ region, pickRegionFor } = {}
) => {
	if (pickRegionFor != null) {
		region = regionFromWorkspaceId(pickRegionFor)
	}
	const firebaseIdToken = await getIdToken()
	const url = `${getFunctionsServerUrl(region)}/${name}?${qs.stringify({
		...params,
		firebaseIdToken,
	})}`
	window.open(url, "_blank")
}

export const toRelativeTimeString = session => {
	if (session == null) {
		return null
	}
	return formatRelative(session.toDate, new Date(), { locale: de })
}
