<template>
	<div :class="$style.sessions">
		<div></div>
		<div :class="$style.header">Org</div>
		<div :class="$style.header">User</div>
		<div :class="$style.header">Session end</div>
		<div :class="$style.header">Duration</div>
		<div :class="$style.header">Pages</div>
		<div :class="$style.header">Version</div>
		<div :class="$style.header"></div>
		<template
			v-for="{ sessions, uid, lastDayActiveString, lastDayPages, latestVersion } of groupedSessions"
			:key="uid"
		>
			<button @click="toggleUidExpanded(uid)">
				{{ uidExpanded(uid) ? "V" : "&gt;" }}
			</button>
			<router-link :to="{ name: 'org', params: { orgId: sessions[0].orgId } }">{{
				sessions[0].orgId
			}}</router-link>
			<div>{{ userById(uid).email }}</div>
			<div>{{ toRelativeTimeString(sessions[0]) }}</div>
			<div>{{ lastDayActiveString }}</div>
			<div>{{ lastDayPages.length }} pages</div>
			<div>{{ latestVersion }}</div>
			<div>(last 24h)</div>
			<template v-for="(session, index) of uidExpanded(uid) ? sessions : []" :key="index">
				<div></div>
				<div></div>
				<div></div>
				<div>{{ toRelativeTimeString(session) }}</div>
				<div>{{ session.durationString }}</div>
				<div>{{ session.routes.length }} pages</div>
				<div>{{ session.version }}</div>
				<router-link
					:to="{ name: 'session', params: { sessionId: session.id, from: session.from } }"
					>details
				</router-link>
			</template>
		</template>
	</div>
</template>

<script>
import { userById } from "@/collections"
import { toRelativeTimeString } from "@/utils.js"

export default {
	data() {
		return {
			expandedUids: [],
		}
	},
	props: ["groupedSessions"],
	methods: {
		userById,
		uidExpanded(uid) {
			return this.expandedUids.includes(uid)
		},
		toggleUidExpanded(uid) {
			if (this.uidExpanded(uid)) {
				this.expandedUids = this.expandedUids.filter(id => id !== uid)
			} else {
				this.expandedUids.push(uid)
			}
		},
		toRelativeTimeString,
	},
}
</script>

<style lang="scss" module>
.sessions {
	display: grid;
	grid-template-columns: 30px repeat(7, auto);
	grid-row-gap: 15px;
	grid-column-gap: 15px;
}

.header {
	font-weight: bold;
}
</style>
