<template>
	<div class="container">
		<h1>Login</h1>
		<p>
			Please log in at <a href="https://dev.condens.io" target="_blank">dev.condens.io</a> and
			reload this page after.
		</p>
		<button @click="reload">Reload</button>
	</div>
</template>

<script>
import { currentUserHasAccess } from "@/utils"
import { watchEffect } from "vue"

export default {
	setup() {
		watchEffect(() => {
			if (currentUserHasAccess()) {
				window.location = "/"
			}
		})
	},
	methods: {
		reload() {
			window.location.reload(true)
		},
	},
}
</script>

<style lang="scss" module>
.container {
	text-align: center;
}
</style>
