<template>
	<div>
		<h1>URL Shortener</h1>
		<div class="shortened-url-entry">
			<div class="shortened-url-slug"><b>Slug</b></div>
			<div class="shortened-url-"><b>URL</b></div>
		</div>
		<div v-for="entry of shortenedUrls" :key="entry.id" class="shortened-url-entry">
			<div class="shortened-url-slug">{{ entry.id }}</div>
			<div class="shortened-url">
				<a :href="entry.url" target="_blank">{{ entry.url }}</a>
			</div>
			<div>
				<button @click="deleteEntry(entry.id)">Delete</button>
			</div>
		</div>
		<button @click="addEntry">Add</button>
	</div>
</template>

<script>
import firebase from "firebase/compat/app"
import "firebase/compat/firestore"

export default {
	computed: {},
	data() {
		return {
			shortenedUrls: [],
			beingEditedId: null,
		}
	},
	methods: {
		deleteEntry(id) {
			if (!window.confirm(`Are you sure you want to delete the slug ${id}?`)) {
				return
			}
			firebase.firestore().collection("shortenedUrls").doc(id).delete()
		},
		addEntry() {
			const slug = window.prompt("Please enter the slug")
			const url = window.prompt("Please enter the URL")
			firebase.firestore().collection("shortenedUrls").doc(slug).set({ url })
		},
	},
	created() {
		this.unsubFirestore = firebase
			.firestore()
			.collection("shortenedUrls")
			.onSnapshot(s => {
				this.shortenedUrls = s.docs.map(d => Object.freeze({ id: d.id, ...d.data() }))
			})
	},
}
</script>

<style lang="scss">
.shortened-url-entry {
	display: flex;
	padding: 6px 0;
	&:hover {
		background-color: #fafafb;
	}
}

.shortened-url-slug {
	width: 210px;
}

.shortened-url {
	width: 600px;
}
</style>
