import { createRouter, createWebHistory } from "vue-router"
import Login from "./pages/Login.vue"
import Dashboard from "./pages/Dashboard.vue"
import UrlShortener from "./pages/UrlShortener.vue"
import Org from "./pages/Org.vue"
import Session from "./pages/Session.vue"
import WebsiteForms from "./pages/WebsiteForms.vue"
import _ from "lodash"

export const router = createRouter({
	routes: [
		{
			path: "/login",
			component: Login,
			name: "login",
		},
		{
			path: "/Dashboard",
			name: "/dashboard",
			component: Dashboard,
		},
		{
			path: "/org/:orgId",
			name: "org",
			component: Org,
			props: true,
		},
		{
			path: "/session/:sessionId/:from",
			name: "session",
			component: Session,
			props: true,
		},
		{
			path: "/urlShortener",
			name: "urlShortener",
			component: UrlShortener,
		},
		{
			path: "/websiteForms",
			name: "websiteForms",
			component: WebsiteForms,
		},
		{ path: "/", redirect: "/dashboard" },
	],
	mode: "history",
	history: createWebHistory(),
})

router.beforeEach((to, from, next) => {
	if (to.name === null) {
		next({ name: "dashboard" })
		return
	}
	next()
})
