<template>
	<div v-if="session != null">
		<h1>Session</h1>
		<div>
			<b>User:</b>
			{{ user.id }}, {{ user.email }}
		</div>
		<!-- <div>
			<b>Workspace:</b>
			{{ session.workspaceId }}
		</div> -->
		<div>
			<b>Version:</b>
			{{ session.version }}
		</div>
		<div v-if="session.browser != null">
			<b>Browser:</b>
			{{ session.browser.name }} {{ session.browser.version }}
			{{ session.browser.os }}
		</div>
		<h2>Pages</h2>
		<div v-for="(page, index) of session.routes" :key="index">
			{{ page.timestampString }}: {{ page.route }}
		</div>
	</div>
</template>

<script>
import firebase from "firebase/compat/app"
import "firebase/compat/firestore"
import { splitUpAndGroupSessions } from "@/utils.js"
import { userById } from "@/collections"

export default {
	computed: {
		session() {
			if (this.sessionDb == null) {
				return null
			}
			return splitUpAndGroupSessions([this.sessionDb])[0].sessions.find(s => s.from == this.from)
		},
		user() {
			return this.userById(this.session.uid)
		},
	},
	methods: {
		userById,
	},
	data() {
		return {
			sessionDb: null,
		}
	},
	watch: {
		sessionId: {
			handler(v) {
				this.unsubAnalytics?.()
				this.unsubAnalytics = firebase
					.firestore()
					.collection("webappAnalytics")
					.doc(this.sessionId)
					.onSnapshot(snapshot => {
						this.sessionDb = Object.freeze({ id: snapshot.id, ...snapshot.data() })
					})
			},
			immediate: true,
		},
	},
	props: ["sessionId", "from"],
}
</script>

<style lang="scss" module>
.users {
	display: grid;
	grid-template-columns: 100px 1fr 1fr 1fr;
	grid-row-gap: 15px;
	grid-column-gap: 15px;
}

.header {
	font-weight: bold;
}
</style>
