<template>
	<div class="website-forms">
		<h1>Website Forms</h1>
		<select v-model="selectedFormId">
			<option value=""></option>
			<option v-for="formId of formIds" :key="formId" :value="formId">
				{{ formId }}
			</option>
		</select>
		<div class="download-button-wrapper" v-if="entries.length > 0">
			<button @click="downloadAsCsv">Download as CSV</button>
		</div>
		<div v-for="entry of entries" :key="entry.id" class="website-form-entry">
			<div v-for="key of keys" :key="key">
				<template v-if="entry.data[key] != null">
					<b>{{ key }}</b
					>:
					{{ entry.data[key] }}
				</template>
			</div>
			<div><b>Submitted</b>: {{ entry.timestamp.toDate() }}</div>
			<button @click="deleteEntry(entry)" class="delete-button">x</button>
		</div>
	</div>
</template>

<script>
import firebase from "firebase/compat/app"
import "firebase/compat/firestore"
import _ from "lodash"
import { Parser as Json2CsvParser } from "@json2csv/plainjs"

export default {
	computed: {
		formIds() {
			return _.sortBy(_.uniq(this.websiteForms.map(f => f.formId)))
		},
		entries() {
			return _.sortBy(
				this.websiteForms.filter(f => f.formId === this.selectedFormId),
				i => -i.timestamp.toMillis()
			)
		},
		keys() {
			return _.uniq(this.entries.flatMap(e => Object.keys(e.data)))
		},
	},
	data() {
		return {
			websiteForms: [],
			selectedFormId: "",
		}
	},
	methods: {
		downloadAsCsv() {
			const parser = new Json2CsvParser()
			const csv = parser.parse(
				this.entries.map(e => ({ ...e.data, submitted: e.timestamp.toDate().toISOString() }))
			)
			const link = document.createElement("a")
			const url = URL.createObjectURL(new Blob([csv], { type: "text/csv;charset=utf-8;" }))
			link.href = url
			link.download = `${this.selectedFormId}.csv`
			link.click()
			setTimeout(() => URL.revokeObjectURL(url), 60000)
		},
		deleteEntry(entry) {
			const confirmText = `delete ${entry.data.email}`
			const result = window.prompt(
				`Are you sure you want to delete the submission from ${entry.data.email}?\n\n⚠️ This can't be undone 💀.\n\n To confirm, type "${confirmText}"`
			)
			if (result === confirmText) {
				firebase.firestore().collection("websiteForms").doc(entry.id).delete()
			}
		},
	},
	created() {
		this.unsubFirestore = firebase
			.firestore()
			.collection("websiteForms")
			.onSnapshot(s => {
				this.websiteForms = s.docs.map(d => Object.freeze({ id: d.id, ...d.data() }))
			})
	},
	unmounted() {
		this.unsubFirestore?.()
	},
}
</script>

<style lang="scss">
.website-forms {
	max-width: 800px;
	margin: 0 auto;
}

.shortened-url-entry {
	display: flex;
	padding: 6px 0;
	&:hover {
		background-color: #fafafb;
	}
}

.shortened-url-slug {
	width: 210px;
}

.shortened-url {
	width: 600px;
}

.download-button-wrapper {
	margin: 15px 0;
}

.website-form-entry {
	white-space: pre-wrap;
	border-bottom: 1px solid gray;
	margin: 15px 0;
	padding-bottom: 15px;
	position: relative;
	.delete-button {
		position: absolute;
		top: 0;
		right: 0;
	}
}
</style>
