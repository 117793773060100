<template>
	<div :class="$style.app">
		<div :class="$style.mainView">
			<router-view v-slot="{ Component }">
				<keep-alive include="Dashboard">
					<component :is="Component" />
				</keep-alive>
			</router-view>
		</div>
	</div>
</template>

<style lang="scss" module>
.app {
	font-family: sans-serif;
	font-size: 14px;
	padding: 0 60px;
}
</style>

<style>
.center {
	text-align: center;
}

.margin {
	margin: 12px;
}
</style>
